export default function downloadFile({type, data, filename}) {
  const blob = new Blob([data], {
    type
  })
  const $a = document.createElement('a')
  $a.href = URL.createObjectURL(blob)
  $a.download = `${filename}`
  document.body.appendChild($a)
  $a.click()
  document.body.removeChild($a)
}
