const FILENAME_HEADER = 'x-suggested-filename'
const CONTENT_TYPE_HEADER = 'content-type'

const getFileHeaders = (headers) => {
  
  return {
    filename: headers[FILENAME_HEADER],
    type: headers[CONTENT_TYPE_HEADER]
  }
}

export default getFileHeaders;
